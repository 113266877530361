<template>
  <div>
    <!-- Vuetify File Input -->
    <v-file-input
        accept="image/*"
        label="Upload Image"
        prepend-icon="mdi-camera"
        @change="handleImageUpload"
        outlined
        dense
    ></v-file-input>

    <!-- Display the uploaded image preview -->
    <a v-if="image || value" :href="image || value" target="_blank">
      <v-img
          v-if="image||value"
          :src="image||value"
          max-width="250"
          max-height="250"
          class="my-2"
      ></v-img>
    </a>

    <!-- Delete Image Button -->
    <v-btn
        v-if="image"
        color="red"
        small
        @click="removeImage"
        class="mt-2"
    >
      Delete
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [File, String],
      default: null,
    },
  },
  data() {
    return {
      image: null, // This will hold the image preview URL
    };
  },
  watch: {
    value(newVal) {
      // Check if newVal is a File or a String URL and handle accordingly
      if (newVal instanceof File) {
        this.image = URL.createObjectURL(newVal);
      } else if (typeof newVal === "string") {
        // Clean up any backslashes in the URL string
        this.image = newVal.replace(/\\/g, "");
      } else {
        this.image = null;
      }
    },
  },
  created() {

  },
  methods: {
    handleImageUpload(event) {
      const file = event; // Directly use the File object from v-file-input
      if (file) {
        this.image = URL.createObjectURL(file); // Create a preview URL for the image
        this.$emit("input", file); // Emit the File object to the parent component
      } else {
        console.error("No file uploaded", event); // Handle cases where no file is selected
      }
    },
    removeImage() {
      this.image = null; // Clear the image preview
      this.$emit("input", null); // Emit null to clear the file
    },
  },
};
</script>
